import { useRef } from "react";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";

import { Button, Layout } from "components";
import { EventDescription, EventTitle } from "views/events/components";

import "./qr.scss";

export const Qr = () => {
  const qrRef = useRef<HTMLDivElement | null>(null);

  const param = new URLSearchParams(window.location.search);
  const sessionId = param.get("sessionId");

  const handleDownload = () => {
    const qrCodeElement = qrRef.current;
    if (!qrCodeElement) return;

    html2canvas(qrCodeElement).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = "qrcode.png";
      link.click();
    });
  };

  if (!sessionId) {
    return null;
  }

  return (
    <Layout
      header={<div className="qr-header">Ticket</div>}
      body={
        <div className="qr-wrapper">
          <div className="qr-container">
            <div className="qr-header-content">
              <EventTitle title="Event Name" />
              <EventDescription
                description={
                  <div className="qr-header-content__time">
                    <i className="ri-calendar-todo-line icon" />{" "}
                    <span>Sep 28, 2024 </span>
                    <i className="ri-time-line icon" />
                    <span>05:00PM</span>
                  </div>
                }
              />
            </div>
            <div className="qr-content">
              <div className="qr-center">
                <div ref={qrRef}>
                  <QRCode value={sessionId} bgColor="#ffffff" />
                </div>

                <div className="qr-meta-section">
                  <EventDescription description="Ticket Number" />
                  <EventTitle title="31231231" />
                </div>
              </div>
            </div>
            <div className="qr-footer">
              <div className="qr-location">
                <i className="ri-map-pin-line icon" />
                <div>
                  <EventTitle title="NYC Stadium" />
                  <EventDescription description="70 Washington Square South, New York, NY 10012, United States" />
                </div>
              </div>

              <div className="qr-meta">
                <div className="qr-meta__inner">
                  <EventDescription description="Section" />
                  <div className="qr-meta__sublabel">FLR 2</div>
                </div>
                <div className="qr-meta__inner">
                  <EventDescription description="Row" />
                  <div className="qr-meta__sublabel">dads</div>
                </div>
                <div className="qr-meta__inner">
                  <EventDescription description="Seat" />
                  <div className="qr-meta__sublabel">52</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      footer={
        <div className="qr-footer-button">
          <Button
            handleClick={handleDownload}
            label="Download Ticket"
            type="button__filled button__filled--primary button__block"
          />
        </div>
      }
    />
  );
};
