import './event-title.scss';

interface IEventTitle {
	title?: string; // Made optional to handle cases when it is not passed
	ticketNumber?: string;
}

export const EventTitle = ({ title, ticketNumber }: IEventTitle) => {
	// Return null early if title is empty or not provided
	if (!title) {
		return null;
	}

	return <div className='event-title--wrapper'>
		<div className="event-title">{title} </div>
		{ticketNumber? <span>[{ticketNumber}]</span> : null }
	</div>
	;
};
