
import './loader.scss';

interface Props {
	dimension?: number;
	className?: 'loader-blue' | 'loader-white' | 'loader-pink';

}

/**
 * Loader component
 *
 * @param {number} Props.dimension - The dimension (width and height) of the loader
 * @param {'loader-blue' | 'loader-white' | 'loader-pink'} Props.className - CSS class for styling the loader
 * @param {'loader' | 'gif'} Props.type - The type of loader ('loader' for CSS loader, 'gif' for GIF loader)
 */

export const Loader = ({
	dimension,
	className = 'loader-blue',
}: Props) => {

	return (
		<div className="circle-loader">
		         <div
					style={{ width: dimension, height: dimension }}
					className={`loader ${className}`}
				/>
		</div>
	);
};
