import {atom} from 'recoil'
import {v4} from 'uuid'
import * as cocoSsd from '@tensorflow-models/coco-ssd';
import { ISessionDetailsData } from '../session-types';
import { IFaceScore, IUser } from 'views/events/components/event-camera/events-match';


interface ISessionEnroll {
    _id: string;
}

export const SessionEnrollState = atom<ISessionEnroll | null>({
    key: v4(),
    default: null
})

export const SessionDetailState = atom<ISessionDetailsData['data'] | null>({
    key: v4(),
    default: null
})

export const ShowPalmMatchState = atom<boolean>({
    key: v4(),
    default: false
})

export const WebrtcSessionIdState = atom<string | null>({
    key: v4(),
    default: null
})

export const WebrtStatusState = atom<string>({
    key: v4(),
    default: ''
})

export const WebrtSessionState = atom<string>({
    key: v4(),
    default: ''
})

export const CocoModelState = atom<cocoSsd.ObjectDetection | null>({
    key: v4(),
    default: null
})

export const CocoDetectedState = atom<cocoSsd.DetectedObject | null>({
    key: v4(),
    default: null
})

export const FaceState = atom<undefined | number>({
    key: v4(),
    default: undefined
})

export const WebrtcCompletedState = atom<boolean>({
    key: v4(),
    default: false
})

export const SelectedDeviceIdState = atom<string>({
    key: v4(),
    default: ''
})

export const DevicesState = atom<MediaDeviceInfo[]>({
    key: v4(),
    default: []
})

export const CameraTypeState = atom<'entry' | 'exit'>({
    key: v4(),
    default: 'entry'
})

export const SelectedCameraSate = atom< MediaDeviceInfo | null
>({
  key: v4(),
  default:  null
});

export const VideoHeightState = atom<number>({
    key: v4(),
    default: 450
})

export interface ISession extends User {
    info: IUser
}

export const SessionInfoState = atom<Record<string, ISession>>({
    key: v4(),
    default: {}
})

export const QrShowedState = atom<Record<string, boolean>>({
    key: v4(),
    default: {}
})

export const UnknownUserState = atom<IUser[]>({
    key: v4(),
    default: []
})

export const SelectedSessionIdState = atom<string | null>({
    key: v4(),
    default: null
})

interface IQr {
    name: string;
    _id: string;
}

export const QrState = atom<IQr[]>({
    key: v4(),
    default: []
})

export interface IEvent {
    _id: string;
    name: string;
    type: any;
    image: string;
    date: string;
    address: string;
    qrIds: QRId[];
    businessId: string;
    description: string;
    users: User[];
    createdAt: string;
    updatedAt: string;
  };

    interface QRId {
    _id: string;
    name: string;
    id: string;
    };
  
export  interface User  {
    status: "PENDING" | "APPROVED";
    name: string;
    phone: string;
    ticketNumber: string;
    metadata: {
		seat: string;
		row: string;
	}
    email: string;
    image: string;
    changedAt: string | null;
    enrolledAt: string;
    attendedAt: null | string;
    sessionId: string;
    _id: string;
    createdAt: string;
    updatedAt: string;
  };

export const EventState = atom<IEvent | null>({
    key: v4(),
    default: null
})

export const AttendedState = atom<Record<string, boolean>>({
    key: v4(),
    default: {}
});
export interface IEventQueue {
    queue: {
        sessionId: string;
    }[]
}

export const EventQueueState = atom<IEventQueue | null>({
    key: v4(),
    default: null
})

export const WebRtcUserState = atom<IFaceScore['users']>({
    key: v4(),
    default: {}
})


export type IEventType = 'both' | 'any' | 'biometric' | 'qr'

export const EventTypeState = atom<IEventType>({
    key: v4(),
    default: 'both'
})

export const EventIdState = atom<string | null>({
    key: v4(),
    default: null
})

export const ModelLoadedState = atom<boolean | null>({
    key: v4(),
    default: null
})