import * as vision from "@mediapipe/tasks-vision";
import * as cocoSsd from '@tensorflow-models/coco-ssd';
import { useCallback, useEffect, useRef } from "react";
import { useSetRecoilState } from "recoil";
import { ModelLoadedState, QrShowedState, ShowPalmMatchState } from "../states";
import FaceMetaInfo from "./face-meta";
import { Memory } from "../memory";
import { worker } from "../App";
import { QRCode } from "jsqr";
import QrScanner from "./scanner";


const { FaceLandmarker, FilesetResolver, DrawingUtils, GestureRecognizer } =
  vision;


const style = {
  lineColor: "#fff",
  lineWidth: 8,
};

let isStarted = false

export const useDroneFace = () => {
  const faceLandmarker = useRef<vision.FaceLandmarker>();
  const gestureRecognizer = useRef<vision.GestureRecognizer>();
  const webcamRunning = useRef(false);
  const videoWidth = useRef<number>()
  const palmMatch = useRef<boolean>(true)
  const lastVideoTime = useRef(-1);
  const faceLandmarkerResults = useRef<vision.FaceLandmarkerResult>();
  const gestureRecognizerResults = useRef<vision.GestureRecognizerResult>();
  const qrCodeResults = useRef<QRCode | null>();

  const predictionsResults = useRef<cocoSsd.DetectedObject[]>();
  const canvasElement = useRef<HTMLCanvasElement>();
  const videoElement = useRef<HTMLVideoElement>();
  const setShowPalmMatch = useSetRecoilState(ShowPalmMatchState);
  const frame_limit = useRef(Number(Memory.frames_limit || localStorage.getItem("frame_limit") || 5));
  const frames = useRef(0);
  const detectData = useRef<cocoSsd.DetectedObject[]>([]);
  const setQrShowed = useSetRecoilState(QrShowedState);
  const setModelLoaded = useSetRecoilState(ModelLoadedState);
  
  // Draw plus sign parameters
  const callbackFunc = useRef<Function>();

  useEffect(() => {
    worker.onmessage = (e) => {
      const qrData = e.data?.data;
      qrCodeResults.current = qrData;
    };
  }, [])

  const drawCanvas = useCallback(async () => {
    const canvasCtx = canvasElement.current?.getContext("2d");
    const canvas = canvasElement.current;
    const video = videoElement.current;
    // videoCamWrapper
    if (!canvasCtx || !video || !canvas) {
      return;
    }
    
    const radio = video.clientHeight / video.clientWidth;
    videoWidth.current = video.clientWidth;

    if (isNaN(radio)) {
      return;
    }

    const drawingUtils = new DrawingUtils(canvasCtx);
    video.style.width = videoWidth.current + "px";
    video.style.height = videoWidth.current * radio + "px";
    canvas.style.width = videoWidth.current + "px";
    canvas.style.height = videoWidth.current * radio + "px";
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    // Now let's start detecting the stream.
    let startTimeMs = performance.now();
    if (lastVideoTime.current !== video.currentTime) {
      lastVideoTime.current = video.currentTime;
      try {
        faceLandmarkerResults.current = faceLandmarker.current?.detectForVideo(
          video,
          startTimeMs,
        );
      } catch(err) {
        console.error("faceLandmarkerResults.err", err)
      }
      
      try {
        gestureRecognizerResults.current =
          gestureRecognizer.current?.recognize(video);
          frames.current = frames.current + 1;
          if (frames.current === frame_limit.current) {
            frames.current = 0;
            Memory.model?.detect(video).then(predictions => {
              predictionsResults.current = predictions;
              detectData.current.push(...predictions);
            })
          }
      } catch(err) {
        console.error("gestureRecognizerResults.err", err)
      }
    }

    canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
    var text = FaceMetaInfo.getValue("hint");
    var fontSize = 40;
    var fontFamily = "Arial";
    canvasCtx.font = fontSize + "px " + fontFamily;
    var textWidth = canvasCtx.measureText(text as string).width;
    var canvasWidth = canvas.width;
    var canvasHeight = canvas.height;
    var xPosition = (canvasWidth - textWidth) / 2;
    var yPosition = fontSize + 20; // Position it just below the top edge of the canvas
    canvasCtx.save();
    canvasCtx.translate(canvasWidth / 2, canvasHeight / 2);
    canvasCtx.scale(-1, 1);
    canvasCtx.translate(-canvasWidth / 2, -canvasHeight / 2);
    canvasCtx.fillStyle = "yellow";
    canvasCtx.fillText(text as string, xPosition, yPosition);
    canvasCtx.restore();

    if (!gestureRecognizerResults.current?.landmarks?.length) {
      style.lineColor = "#fff";
      setShowPalmMatch(false);
    } else {
      setShowPalmMatch(true);
    }

    // emit
    if (faceLandmarkerResults.current?.faceLandmarks) {
      for (const landmarks of faceLandmarkerResults.current.faceLandmarks ??
        []) {

        // Draw face landmarks
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_TESSELATION,
          { color: "#C0C0C070", lineWidth: 1 }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE,
          { color: "#C0C0C070" }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_RIGHT_EYEBROW,
          { color: "#C0C0C070" }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_LEFT_EYE,
          { color: "#C0C0C070" }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_LEFT_EYEBROW,
          { color: "#C0C0C070" }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_FACE_OVAL,
          { color: "#C0C0C070" }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_LIPS,
          {
            color: "#C0C0C070",
          }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_RIGHT_IRIS,
          { color: "#C0C0C070" }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_LEFT_IRIS,
          { color: "#C0C0C070" }
        );
      }
    }

    callbackFunc.current?.(
      faceLandmarkerResults.current,
      gestureRecognizerResults.current,
      predictionsResults.current,
    );

    // Call this function again to keep predicting when the browser is ready.
    if (webcamRunning.current === true) {
      window.requestAnimationFrame(drawCanvas);
    }
  }, [setShowPalmMatch]);

  const enableCam = useCallback(
    (draw?: boolean, deviceId?: string) => {
      if (videoElement.current) {
        webcamRunning.current = true;
        if (draw) {
            if (videoElement.current) {
              const qrscanner = new QrScanner(videoElement.current, (result) => { 
                const qrValue = result?.data
                const isValid = !!Memory.eventUserList.find(user => user.sessionId === qrValue);
                
                Memory.showedQrs[qrValue] = isValid;
                setQrShowed(prev=> {
                  const cloned = JSON.parse(JSON.stringify(prev));
                  cloned[qrValue] = isValid
                  return cloned;
                });

                if (isValid) {
                  document.body.classList.remove("invalid-qr");
                  document.body.classList.add("success-qr");    
                } else {
                  document.body.classList.remove("success-qr");    
                  document.body.classList.add("invalid-qr");    
                }
              }, {
              
                highlightScanRegion:true,
                highlightCodeOutline:true,
                maxScansPerSecond: 144,
              })
              if (!isStarted) {
                isStarted = true
                qrscanner.start() 
              }
            }
            drawCanvas()
        }
      }
    },
    [drawCanvas, setQrShowed]
  );

  // Before we can use HandLandmarker class we must wait for it to finish
  // loading. Machine Learning models can be large and take a moment to
  // get everything needed to run.
  const start = useCallback(
    async ({
      video,
      canvas,
      draw = true,
      deviceId,
      onResults,
      palm = true,
      numFaces = 1
    }: {
      video: HTMLVideoElement;
      canvas: HTMLCanvasElement;
      blendShapes?: HTMLElement | null;
      draw?: boolean;
      deviceId?: string;
      numFaces?: number;
      palm?: boolean;
      onResults?: (results?: vision.FaceLandmarkerResult) => void;
    }) => {
      setModelLoaded(false);
      videoElement.current = video;
      canvasElement.current = canvas;

      callbackFunc.current = onResults;
      const filesetResolver = await FilesetResolver.forVisionTasks(
        "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm"
      );

      faceLandmarker.current = await FaceLandmarker.createFromOptions(
        filesetResolver,
        {
          baseOptions: {
            modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
            delegate: "GPU",
          },
          outputFaceBlendshapes: true,
          runningMode: "VIDEO",
          numFaces,
        }
      );

      palmMatch.current = palm

      if (palm) {
        gestureRecognizer.current = await GestureRecognizer.createFromOptions(
          filesetResolver,
          {
            baseOptions: {
              modelAssetPath:
                "https://storage.googleapis.com/mediapipe-models/gesture_recognizer/gesture_recognizer/float16/1/gesture_recognizer.task",
              delegate: "GPU",
            },
            numHands: 2,
            runningMode: "IMAGE",
          }
        );
      }

      setModelLoaded(true);
      enableCam(draw, deviceId);
    },
    [enableCam, setModelLoaded]
  );

  const draw = useCallback(async () => {
    await drawCanvas();
  }, [drawCanvas]);

  return { start, draw };
};
