import { v4 } from "uuid";
import * as cocoSsd from '@tensorflow-models/coco-ssd';
import { IEvent, ISession, User } from "states";
import { IQRData } from "views/events/components/event-camera/events-match";

export class Memory {
    static sessionId: string = v4()
    static connectionMade = false
    static attended: Record<string, boolean> = {};
    static entryQueue: Record<string, boolean> = {};
    static exitQueue: Record<string, boolean> = {};
    static sessionVudeoUrl = '';
    static frames: number | null = null
    static frames_limit: number | null = null
    static match: boolean;
    static timeout: number | null = null
    static useSocket: boolean = /true/gi.test(localStorage.getItem("socket") ?? '');
    static model: cocoSsd.ObjectDetection | null = null
    static webrtcConnected: boolean = false;
    static faceScore: number = 0
    static selfieBinary: ArrayBuffer
    static eventUsers: IEvent['users']
    static detection = {
        externalDevice: false,
        isPersonScoreLow: false
    }
    static QrData: IQRData[] = []
    static eventId: string;
    static eventUserList: User[] = [];
    static sessions: Record<string, ISession> = {}
    static unknownSessions: Record<string, boolean> = {}
    static showedQrs: Record<string, boolean> = {};
}