import React, { useState, useEffect, FC, useCallback, useRef } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { CameraTypeState, DevicesState, SelectedCameraSate, SelectedDeviceIdState } from './states';

interface Props {
    handleChange?: (deviceId: string) => void
}

let initalCameraSetup = false;

export const CameraSelector: FC<Props> = () => {
  const [devices, setDevices] = useRecoilState(DevicesState);
  const [selectedDeviceId, setSelectedDeviceId] = useRecoilState(SelectedDeviceIdState);
  const [cameraType, setCameraType] = useRecoilState(CameraTypeState);
  const setSelectedCamera = useSetRecoilState(SelectedCameraSate);

  const isEvent = /events/gi.test(document.location.pathname)

  useEffect(() => {
    // Get list of video input devices (cameras)
    const getCameraDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        console.log('videoDevices', videoDevices);
        setDevices(videoDevices);
        if (videoDevices.length) {
          initalCameraSetup = true;
          setSelectedDeviceId(videoDevices[0].deviceId)
          setSelectedCamera(videoDevices[0])
        }
      } catch (error) {
        console.error('Error accessing camera devices:', error);
      }
    };

    if (!initalCameraSetup) {
      getCameraDevices();
    }
  }, []);

  const handleDeviceChange = useCallback((event: any) => {
    const deviceId = event.target?.value;
    setSelectedDeviceId(deviceId);
    
    const foundCamera = devices?.find(d => d.deviceId === deviceId)
    
    if (foundCamera) {
      setSelectedCamera(foundCamera)
    }

  }, [devices, setSelectedCamera, setSelectedDeviceId]);

  const handleCameraTypeChange = useCallback((event: any) => {
    const deviceId = event.target?.value;
  
    setCameraType(deviceId);
  }, [setCameraType]);

  console.log('devices', devices);

  return (
    <div>
        {
            isEvent ?  
            <div style={{paddingBottom: 20}} >
            <FormControl fullWidth>
            <InputLabel id="camera-type-label">Camera Type</InputLabel>
            
            <Select
              labelId="camera-type-label"
              value={cameraType}
              onChange={handleCameraTypeChange}
              label="Camera Type"
              sx={{ height: 40 }}
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 200 }  // Optional: Adjust max height of the dropdown menu
                }
              }}
            >
              {['entry', 'exit'].map(type => (
                <MenuItem key={type} value={type}>
                  {type.slice(0, 1).toUpperCase()}{type.slice(1)} Camera
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </div> : null
          }
       <div>
        <FormControl fullWidth>
          <InputLabel id="camera-select-label">Select Camera</InputLabel>
          <Select
            labelId="camera-select-label"
            value={selectedDeviceId}
            onChange={handleDeviceChange}
            label="Select Camera"
            sx={{ height: 40 }}
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 200 }  // Optional: Adjust max height of the dropdown menu
              }
            }}
          >
            {devices.map(device => (
              <MenuItem key={device.deviceId} value={device.deviceId}>
                {device.label || `Camera ${device.deviceId}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};
