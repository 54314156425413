import "./event-description.scss";

interface IEventDescription {
  description: string | JSX.Element; // Made optional to handle cases when it is not passed
  className?: string;
}

export const EventDescription = ({
  description,
  className,
}: IEventDescription) => {
  // Return null early if description is empty or not provided
  if (!description) {
    return null;
  }

  const customClass = `event-description ${className}`;

  return <div className={customClass}>{description}</div>;
};
