import { useCallback, useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import {  useRecoilValue, useSetRecoilState } from "recoil";
import {
  EventIdState,
  EventState,
  EventTypeState,
  IEvent,
  ISession,
  SessionInfoState,
  UnknownUserState,
  WebrtcCompletedState,
  WebrtcSessionIdState,
  WebRtcUserState,
  WebrtSessionState,
} from "states";
import { Memory } from "memory";
import { onValue, ref } from "firebase/database";
import { database } from "utils/firebase";
import ScoreModal from "score-modal";

export interface IUser {
  BoundingBox: {
    Height: number;
    Left: number;
    Top: number;
    Width: number;
  };
  cropped_image: string;
  is_spoof: boolean;
  similarity: number;
  verified: boolean;
  qrShowed: boolean;
  is_occluded:boolean
  is_upscaled:boolean
}

export interface IQRData {
  bounding_box: {
    Height: number
    Left: number
    Top: number
    Width: number
  }
  data: string;
}

export interface IFaceScore {
  users?: Record<string, IUser>;
  qr_data?: IQRData[];
}

export const EventMatching = () => {
  const webrtcSessionId = useRecoilValue(WebrtcSessionIdState);

  const setWebrtcSession = useSetRecoilState(WebrtSessionState);
  const eventId = useRecoilValue(EventIdState);
  const spoofs = useRef<number[]>([]);
  const setSessionInfo = useSetRecoilState(SessionInfoState);
  const setUnknownUsers = useSetRecoilState(UnknownUserState);
  const setWebRtcUsers = useSetRecoilState(WebRtcUserState);

  const setEvent = useSetRecoilState(EventState);
  const eventType = useRecoilValue(EventTypeState);
  
  const totalFrames = useRef<number>(0);

  const [showModal, setModal] = useState(false);
  const webrtcCompleted = useRecoilValue(WebrtcCompletedState);

  useEffect(() => {
    if (webrtcCompleted) {
      if (Memory.timeout) {
        setModal(true);
      }
    }
  }, [webrtcCompleted]);

  const update = useCallback((data: IFaceScore) => {
    if (!Memory.webrtcConnected) {
      Memory.webrtcConnected = true;
    }
  }, []);

  useEffect(() => {
    const type = eventType;
    if (webrtcSessionId && eventId) {
      const dataRef = ref(database, webrtcSessionId);

      onValue(dataRef, async (snapshot) => {
        const data: IFaceScore = snapshot.val();

        // for entry and exits
        if (data?.users) {
          setWebRtcUsers(data.users)
        }

        let isData = null
        const {qr_data: qrRawData = []} = data ?? {}
        Memory.QrData = qrRawData;
        const isQr = !!qrRawData.length

        const users = JSON.parse(JSON.stringify(data?.users ?? {}));

        Object.keys(users).forEach(sessionId => {
          users[sessionId].verified = true;
        });

        const qrData = qrRawData.map(({data}) => data)
        let sessionsList = qrData ?? [];

        switch(type) {
          case 'qr': {
            isData = isQr;
            sessionsList = qrData ?? [];
            break
          }
          case 'biometric': {
            isData = data?.users
            sessionsList = Object.keys(users);
            break
          }
          case 'both': {
            isData = users || isQr;
            Object.keys(users).forEach((sessionId) => {
              const user = users[sessionId];
              const foundQr = qrData.find(qrValue => qrValue === sessionId);
              if (foundQr) {
                user.verified = true;
                user.qrShowed = true;
              } else {
                user.verified = false;
                user.qrShowed = true;
              }
            });
            sessionsList = Object.keys(users);
            break;
          }
          case 'any': {
            Object.keys(users).forEach((sessionId) => {
              const user = users[sessionId];
              const foundQr = qrData.find(qrValue => qrValue === sessionId);
              if (foundQr) {
                user.qrShowed = true;
                user.verified = true;
              } else {
                user.qrShowed = false;
                user.verified = true;
              }
            });

            isData = users || isQr;
            const sessionSet = new Set([...Object.keys(users), ...qrData]);
            sessionsList =  Array.from(sessionSet);
            break;
          }
        }

        if (!isData) {
          setSessionInfo({});
          return;
        }
        
        // console.log('users', users);
        setWebrtcSession("Fetching Sessions");
        
        const WebRtcSession: Record<string, ISession> = {};
        const unknowns: IUser[] = [];
        const eventUsers: IEvent['users'] = JSON.parse(JSON.stringify(Memory.eventUsers ?? []));
        const eUsers: IEvent['users'][] = []
        sessionsList.forEach(sessionId => {
          const userInfo = users[sessionId] ?? {};
          if (Memory.sessions[sessionId]) {
            WebRtcSession[sessionId] = JSON.parse(JSON.stringify(Memory.sessions[sessionId]));
            WebRtcSession[sessionId].info = userInfo;
            return;
          }

          if (/unknown/gi.test(sessionId)) {
            if (Memory.unknownSessions[sessionId]) {
              return;
            }
            Memory.unknownSessions[sessionId] = true;
            unknowns.push(userInfo)
            return;
          }

          const foundUser = eventUsers.find((user) => user.sessionId === sessionId)
          
          // console.log('foundUser', foundUser);
          
          if (!foundUser) {
            console.error("user not found");
            return;
          }
          
          const attendedAt = new Date().toISOString();
          foundUser.attendedAt = attendedAt;
          
          const session = JSON.parse(JSON.stringify(foundUser));
          session._id = sessionId;
          session.info = userInfo;

          WebRtcSession[sessionId] = session;
          eUsers.push(session);
          Memory.sessions[sessionId] = session;
        })
        Memory.eventUsers = eventUsers;

        setUnknownUsers((prev) => [...unknowns, ...prev]);
        // setUnknownUsers(unknowns);
        setSessionInfo(WebRtcSession);
        if (eUsers.length) {
          setEvent(prev => {
            if (!prev) {
              return prev;
            }
            return {...prev, users: eventUsers};
          });
        }
        // for (const sessionId of Object.keys(users))
        setWebrtcSession("Connected");
        update(data);
      });

      // Clean up the listener when the component unmounts
      // return () => {
      //   off(dataRef);
      // };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webrtcSessionId, eventId]);

  return (
    <Grid maxWidth="sm" sx={{ marginTop: 0 }}>
      {showModal ? (
        <ScoreModal
          open={true}
          onClose={() => setModal(false)}
          children={
            <>
              <div>
                In this session stream, spoofing was detected in{" "}
                {((spoofs.current.length / totalFrames.current) * 100).toFixed(
                  2
                )}
                % of the frames
              </div>
              <div>
                <video
                  autoPlay
                  src={Memory.sessionVudeoUrl}
                  style={{
                    width: 650,
                    height: 400,
                  }}
                ></video>
              </div>
            </>
          }
        />
      ) : null}
    </Grid>
  );
};
