import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { formatCurrentDate } from "utils";

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

interface IEventExitEntryTable {
  name: string;
  data: any[];
}

export const EventExitEntryTable = ({ name, data }: IEventExitEntryTable) => {
  if (!data.length) {
    return (
      <div style={{ height: "400px", width: "400px" }}>
        <div className="content-center">
          <div className="event-empty-list">
            <img
              loading="eager"
              src="https://storage.googleapis.com/satschel-assets-public/images/simplici-media/noData.svg"
              alt="noData.svg"
              className="empty-table__image"
            />
            <div className="event-empty-list__message">
              No Results Found
              <div className="event-empty-list__sub-message">
                Sorry, no results were found.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell> Name</TableCell>
            <TableCell align="right">Entry At</TableCell>
            <TableCell align="right">Exit At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row._id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {name}
              </TableCell>
              <TableCell align="right">
                {row.entryAt ? formatCurrentDate(row.entryAt) : "N/A"}
              </TableCell>
              <TableCell align="right">
                {row.exitAt ? formatCurrentDate(row.exitAt) : "N/A"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
