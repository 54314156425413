import { DateTime } from 'luxon';

export function removeDuplicateObjects(arr: any[]) {
    return Array.from(new Set(arr.map(obj => JSON.stringify(obj))))
      .map(str => JSON.parse(str));
}

export const formatCurrentDate = (date: number): string  =>{
	const now = DateTime.fromMillis(date)
	return now.toFormat('MM-dd-yyyy HH:mm');
  }
  
