import type {
  IEventTag,
  IProfileSectionSkelton,
  IProfileSection,
} from "./types";

import {
  Fragment,
  MouseEvent,
  useCallback,
  useMemo,
  useState,
} from "react";

import { Skeleton } from "components";

import { getDateTime, TAG_CSS } from "../../constants";
import { EventDescription } from "../event-description";
import { EventTitle } from "../event-title";

import {
  convertToCurrencySystem,
  formatNumberWithCommasAndCurrencySignSign,
} from "utils";

import "./profile.scss";
import {
  CameraTypeState,
  EventQueueState,
  EventTypeState,
  QrShowedState,
  SelectedSessionIdState,
} from "states";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { QrCodeOutlined } from "@mui/icons-material";
import qrCheck from "./qr-check.png";
import Modal from "components/new-modal/modal";
import { EventExitEntryTable } from "../event-exit-entry-table";

const EventTag = ({ name }: IEventTag) => {
  if (!name) {
    return <></>;
  }
  const { color, background } = TAG_CSS[name.toLowerCase()] ?? {};
  return (
    <div
      className="event-profile-tag"
      style={{
        color,
        background,
      }}
    >
      {name}
    </div>
  );
};

export const ProfileSectionSkelton = ({
  className = "",
}: IProfileSectionSkelton) => {
  return (
    <div className={`d-flex align-item-center gp-8 ${className}`}>
      <Skeleton height={108} width={80} borderRadius={4} />
      <div className={`d-flex gp-4 direction-column `}>
        <Skeleton width={120} height={20} borderRadius={4} />
        <Skeleton width={220} height={20} borderRadius={4} />
        <Skeleton width={170} height={20} borderRadius={4} />
        <div className="d-flex align-item-center gp-8">
          <Skeleton width={55} height={20} borderRadius={4} />
          <Skeleton width={55} height={20} borderRadius={4} />
          <Skeleton width={55} height={20} borderRadius={4} />
        </div>
      </div>
    </div>
  );
};

export const ProfileSection = ({
  className = "",
  name = "N/A",
  email = "N/A",
  img,
  phone = "N/A",
  tags = [],
  isSpoof = false,
  similarity = null,
  ticketNumber,
  date = undefined,
  sessionId,
  type = "",
  seat = "12",
  row = "20",
  verified,
  attended,
  from,
}: IProfileSection) => {
  const setSelectedSessionId = useSetRecoilState(SelectedSessionIdState);
  const eventType = useRecoilValue(EventTypeState);
  const cameraType = useRecoilValue(CameraTypeState);
  const qrsShowed = useRecoilValue(QrShowedState);
  const queueEvent = useRecoilValue(EventQueueState);
  const [history, setHistory] = useState<any[]>([]);
  const [currentSessionId, setCurrentSesssionId] = useState("");

  const faceStyle = useMemo(() => {
    if (similarity == null) {
      return {};
    }

    if (isSpoof === true) {
      return {
        color: "red",
      };
    }

    if (similarity < 50) {
      return {
        color: "red",
      };
    } else if (similarity > 50 && similarity < 80) {
      return {
        color: "#FFBF00",
      };
    } else if (similarity > 80) {
      return {
        color: "green",
      };
    }
    return {};
  }, [similarity, isSpoof]);

  const isInvalid = verified === false || isSpoof;
  const isExit = cameraType === "exit";

  const attend = useMemo(() => {
    if (from === "matched") {
      return;
    }

    if (["any", "both", "qr"].includes(eventType)) {
      // eslint-disable-next-line eqeqeq
      if (attended == undefined || verified !== undefined) {
        return <QrCodeOutlined fontSize="large" />;
      }
      return (
        <img
          style={{
            width: "42px",
            height: "42px",
            objectFit: "contain",
          }}
          alt="none"
          src={qrCheck}
        />
      );
    }
    return null;
  }, [attended, eventType, from, verified]);

  const verify = useMemo(() => {
    if (from !== "matched") {
      return;
    }

    if (isExit) {
      return;
    }

    switch (eventType) {
      case "both": {
        const qrShowed = qrsShowed[sessionId];
        if (qrShowed !== undefined) {
          if (qrShowed === false) {
            return <QrCodeOutlined color={"error"} />;
          }
          return (
            <img
              style={{
                width: "42px",
                height: "42px",
                objectFit: "contain",
              }}
              alt="none"
              src={qrCheck}
            />
          );
        }
        return <QrCodeOutlined />;
      }
      case "any": {
        const qrShowed = qrsShowed[sessionId];
        if (qrShowed !== undefined) {
          if (qrShowed === false) {
            return <QrCodeOutlined color={"error"} />;
          }
          return (
            <img
              style={{
                width: "42px",
                height: "42px",
                objectFit: "contain",
              }}
              alt="none"
              src={qrCheck}
            />
          );
        }
        return <QrCodeOutlined />;
      }
      case "qr": {
        return <QrCodeOutlined />;
      }
      case "biometric": {
        return null;
      }
      default: {
        return null;
      }
    }
  }, [eventType, from, isExit, qrsShowed, sessionId]);

  const qrShowed = qrsShowed[sessionId];

  const handleUserEventInfo = useCallback(
    (event: MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      setCurrentSesssionId(sessionId);
      const historys = (queueEvent as any)?.history?.filter(
        (el: any) => el.sessionId === sessionId
      );
      const queues = (queueEvent)?.queue?.filter(
        (el: any) => el.sessionId === sessionId
      );
      setHistory([...queues ?? [] , ...historys.reverse()]);
    },
    [sessionId, queueEvent]
  );

  const closeEventMeta = useCallback(() => {
    setCurrentSesssionId("");
  },[])
  return (
    <Fragment>
      <div
        onClick={() => setSelectedSessionId(sessionId)}
        className={`d-flex gp-8 ${className} profile-wrapper ${
          !isExit
            ? qrShowed === undefined && from === "matched"
              ? !qrShowed
                ? "spoof"
                : ""
              : ""
            : ""
        }`}
      >
        <div className="image-container">
          <img className="profile-body-img" src={img} alt={`${name}_image`} />

          <div onClick={handleUserEventInfo as any} className="event-meta-icon">
            <i className="ri-eye-line"></i>
          </div>
          {similarity ? (
            <span style={faceStyle}>{similarity?.toFixed(2)}%</span>
          ) : null}
        </div>
        <div>
          <div className="d-flex gp-8 space-between">
            <div style={{ width: "calc(100% - 50px)" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <EventTitle title={name} ticketNumber={ticketNumber} />
              </div>
              <EventDescription description={email} className="break" />
              <EventDescription description={phone} />

              <div className="d-flex align-item-center gp-8">
                {tags.map((el: string, index: number) => (
                  <EventTag name={el} key={email + index.toString() + el} />
                ))}
              </div>
            </div>

            <div>
              {attend}
              {verify}
            </div>
          </div>
          <div>
            <span style={{ fontSize: 11 }}>
              <b>{type ? `${type} on:` : ""}</b>{" "}
              {date ? getDateTime(date) : "-"}
            </span>
          </div>

          <div className="profile-meta">
            <div className="br-right profile-meta__inner">
              <EventDescription description={"Price"} />
              <div className="profile-meta__sublabel">
                {Math.abs(Number(15.0)) <= 1.0e3
                  ? formatNumberWithCommasAndCurrencySignSign("15.0", "$")
                  : convertToCurrencySystem("1599999999.00")}
              </div>
            </div>
            <div className="profile-meta__inner">
              <EventDescription description={"Section"} />
              <div className="profile-meta__sublabel">FLR 2</div>
            </div>
            <div className="profile-meta__inner">
              <EventDescription description={"Row"} />
              <div className="profile-meta__sublabel">{row}</div>
            </div>
            <div className="profile-meta__inner">
              <EventDescription description={"Seat"} />
              <div className="profile-meta__sublabel">{seat}</div>
            </div>
          </div>
        </div>
      </div>

      {sessionId === currentSessionId && (
        <Modal
          isOpen={sessionId === currentSessionId}
          modalName={""}
          closeModal={closeEventMeta}
        >
          <EventExitEntryTable name={name} data={history} />
        </Modal>
      )}
    </Fragment>
  );
};
