import { useEffect, useRef, useState } from "react";
import { useCallback } from "react";
import { API_URL } from "../api";
import {
  EventTypeState,
  SelectedDeviceIdState,
  SessionDetailState,
  WebrtStatusState,
  WebrtcCompletedState,
  WebrtcSessionIdState,
} from "../states";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { ISessionDetailsData } from "../session-types";
import { Memory } from "../memory";
import axios from "axios";
import qs from 'qs'; 

interface CustomRTCRtpEncodingParameters extends RTCRtpEncodingParameters {
  scalabilityMode?: string;
}

// const TWILIO_ACCOUNT_SID= "AC4ca758730401d73f89764a745da8d0d3";
// const TWILIO_AUTH_TOKEN= "f91b7c599aa4d7eb53012e011c4adb8b";

const url = "https://rtc.live.cloudflare.com/v1/turn/keys/b35fb8b0c967c32766c2009f8037b67b/credentials/generate"

function convertIceServers(iceServers: any) {
  const { urls, username, credential } = iceServers;

  return urls.map((url: string, index: number) => {
      const server: Record<string, any> = { urls: [url] };
      if (url.startsWith('turn:') || url.startsWith('turns:')) {
          server.username = username;
          server.credential = credential;
      }
      return server;
  });
}

function modifyTurnUrls(servers: any[], region: string) {
  return servers.map(entry => {
      if (entry.url.includes("turn:global")) {
          entry.url = entry.url.replace("turn:global", `turn:${region}`);
      }
      if (entry.urls.includes("turn:global")) {
          entry.urls = entry.urls.replace("turn:global", `turn:${region}`);
      }
      return entry;
  });
}


const getIceServers = async () => {
  try {
    // const data = qs.stringify({
    //   Ttl: localStorage.getItem("ttl") || 360000
    // });

    // const config = {
    //   method: 'post',
    //   url: `https://api.twilio.com/2010-04-01/Accounts/${TWILIO_ACCOUNT_SID}/Tokens.json`,
    //   headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded'
    //   },
    //   auth: {
    //     username: TWILIO_ACCOUNT_SID,
    //     password: TWILIO_AUTH_TOKEN
    //   },
    //   data: data
    // };
    // const iceServers = response.data.ice_servers;

    // const region = localStorage.getItem("region")
    // let servers = iceServers;
    // if (region) {
    //   servers = modifyTurnUrls(iceServers, region)
    // }

    const data = {
      "ttl": 86400
    }

    const token = localStorage.getItem("ice_token") ?? '0bc1be38d16ea2affdf4a4dac1724c23432008cd616e5e29171fc82297bf4eac'
    const config = {
      method: 'post',
      url: url,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: data
    };

    const response = await axios(config);
    if (!response?.data) {
      return null;
    }
    const iceServers = response.data.iceServers;

    return convertIceServers(iceServers);
  } catch (error) {
    console.error('Error fetching ICE servers:', error);
    return null
  }
};

const usePeerConnection = () => {
  const API_HOST =
    // "https://cee6-2405-201-5800-2bb8-b907-d112-d7fb-a70c.ngrok-free.app";
    "https://events.backendly.io";
  // local state
  const [peerConnection, setPeerConnection] =
    useState<RTCPeerConnection | null>(null);

  // Refs
  const localStream = useRef<MediaStream | null>(null);
  const datachannel = useRef<RTCDataChannel | null>(null);
  const stopOverCall = useRef<boolean>(true);
  const faceMatchsResult = useRef<string>("");

  // global states
  const setWebrtcSessionId = useSetRecoilState(WebrtcSessionIdState);
  const setWebrtcCompleted = useSetRecoilState(WebrtcCompletedState);
  const [selectedDeviceId, setSelectedDeviceId] = useRecoilState(SelectedDeviceIdState);

  const setWebrtStatus = useSetRecoilState(WebrtStatusState);
  const eventType = useRecoilValue(EventTypeState);


  useEffect(() => {
    if (datachannel.current) {
      console.log("end here----");
      datachannel.current.send(
        JSON.stringify({
          end: true,
        })
      );
    }
  }, []);

  const cameraClose = useCallback(() => {
    if (localStream.current) {
      // Get the media tracks from the stream
      const tracks = localStream.current.getTracks();
      console.log("--------- steam on close ", tracks);

      // Stop each track
      tracks.forEach((track) => track.stop());

      // Clear the stream reference
      localStream.current = null;
      console.log("localStream.current", localStream.current);
    }
  }, [localStream]);

  const negotiate = useCallback(
    async (pc: RTCPeerConnection) => {
      console.log("Creating Offer", pc);
      return pc
        ?.createOffer()
        .then(function (offer) {
          console.log("Creating Offer-1  ");
          return pc.setLocalDescription(offer);
        })
        .then(function () {
          // wait for ICE gathering to complete
          return new Promise<void>((resolve) => {
            function checkState() {
              if (pc.iceGatheringState === "complete") {
                setWebrtStatus("Authenticating...");
                pc.removeEventListener("icegatheringstatechange", checkState);
                resolve();
              }
            }
            pc.addEventListener("icegatheringstatechange", checkState);
          });
        })
        .then(function () {
          console.log("Sending offer to signalling server");
          const offer: any = pc.localDescription;
          
          // offer.sdp = offer.sdp.replace(
          //   /a=fmtp:\d+.*\r\n/g,
          //   "a=fmtp:96 x-google-min-bitrate=3000; x-google-max-bitrate=5000\r\n"
          // );
          
          // offer.sdp = offer.sdp.replace(
          //   /a=rtpmap:96 VP8\/90000\r\n/g,
          //   "a=rtpmap:96 VP8/90000\r\n" +
          //   "a=fmtp:96 max-fs=12288; max-fr=60; x-google-min-bitrate=3000; x-google-max-bitrate=5000\r\n"
          // );

          // For sending data after connection whatver we want
          setWebrtcSessionId(Memory.sessionId);
          const type = eventType
          const metadata = JSON.stringify({
            webRtcSessionId: Memory.sessionId,
            collection_id: Memory.eventId,
            type
          });
          return fetch(API_HOST + API_URL.WEBRTC_CONNECTION, {
            body: JSON.stringify({
              sdp: offer?.sdp,
              type: offer?.type,
              video_transform: "compare",
              metadata,
            }),
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
          });
        })
        .then(function (response: { json: () => any }) {
          console.log("response", response);
          return response.json();
        })
        .then(function (answer: any) {
          console.log("Got answer", answer);
          return pc.setRemoteDescription(answer);
        })
        .catch(function (e: any) {
          console.error(e);
        });
    },

    [eventType, setWebrtStatus, setWebrtcSessionId]
  );

  const createPeerConnection = useCallback(
    async () => {
      Memory.connectionMade = true;

      if (!stopOverCall.current) {
        return;
      }
      stopOverCall.current = false;
      console.log("initializePeerConnection--inside");
      // Get user media
      
      const videoSettings: MediaStreamConstraints = {
        video: { 
          deviceId: { exact: selectedDeviceId },
          width: { ideal: 1920 }, // Set the desired width in pixels
          height: { ideal: 1080 }, // Set the desired height in pixels
        },
        audio: false,
      }
      try {
        const [servers, stream] = await Promise.all([getIceServers(), navigator.mediaDevices.getUserMedia(videoSettings)]);

        localStream.current = stream;
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorder.start();
        const recordedChunks: Blob[] = [];
  
        mediaRecorder.ondataavailable = event => {
          if (event.data.size > 0) {
              recordedChunks.push(event.data);
          }
        };
  
        mediaRecorder.onstop = () => {
          const blob = new Blob(recordedChunks, { type: 'video/webm' });
          const url = URL.createObjectURL(blob);
          Memory.sessionVudeoUrl = url;
          setWebrtcCompleted(true);
        };
        // relay2.expressturn.com:443
        // relay3.expressturn.com:80
        // relay3.expressturn.com:443
        // relay4.expressturn.com:3478
        // relay5.expressturn.com:3478
        // relay6.expressturn.com:3478
        // relay7.expressturn.com:3478
        // relay8.expressturn.com:3478
        // efPC58ODRTKQ34XQ21
        // vruXB9vsnFT292jz
        const backupConfig: RTCConfiguration = {
          iceServers: [
            // {
            //   urls: ['turn:relay2.expressturn.com:443', 'turn:relay3.expressturn.com:443', 'turn:relay4.expressturn.com:3478'],
            //   username: 'efPC58ODRTKQ34XQ21',
            //   credential: 'vruXB9vsnFT292jz'
            // },
            {
              urls: ['stun:stun2.l.google.com:19302']
            },
            {
              urls: ['stun:stun4.l.google.com:19302']
            },
            {
              urls: ['stun:stun.l.google.com:19302']
            },
          ],
        };

        const iceServers = servers ?? backupConfig.iceServers
        console.log('servers', iceServers);

        const customServers = [
          {
              urls: ["stun:34.172.74.158:3478"],
          },
          {
              urls: ["turn:34.172.74.158:3478?transport=udp"],
              username: "libface",
              credential: "6ZnoNBo004wgkjdf43",
          },
          {
              urls: ["turn:34.172.74.158:3478?transport=tcp"],
              username: "libface",
              credential: "6ZnoNBo004wgkjdf43",
          }
        ]
        console.log('servers', servers);
        const config = {
          iceServers: servers
        }

        // const config = {
        //   sdpSemantics: "unified-plan",
        //   iceServers: [
        //     {
        //       urls: "turn:relay2.expressturn.com:443",
        //       username: "efPC58ODRTKQ34XQ21",
        //       credential: "vruXB9vsnFT292jz",
        //     },
        //   ],
        // };

        // Create peer connection
        const pc = new RTCPeerConnection(config);
        console.log("pc", pc);
        setWebrtStatus("Creating Connection...");

        // Add local stream to peer connection
        stream.getTracks().forEach((track) => pc.addTrack(track, stream));

        const sender = pc.getSenders().find(s => s.track?.kind === 'video');

        //  Configure VP9 SVC
        // if (sender) {
        //   const params = sender.getParameters();
        //   if (!params.encodings) {
        //     params.encodings = [{}]; // Create the encodings array if it doesn't exist
        //   }

        //   // params.encodings[0].scaleResolutionDownBy = 1; // Keep the original resolution
        //   params.encodings[0].maxBitrate = 3000000; // Set a high max bitrate
        //   (params.encodings[0] as any).scalabilityMode = 'L1T3'; // Set a high max bitrate
        //   params.encodings[0].rid = 'q';
        //   params.encodings[0].networkPriority = 'high';
        //   params.encodings[0].priority = 'high';

        //   await sender.setParameters(params);
        // }

        setPeerConnection(pc);

        // create channel
        console.log("Creating datachannel");
        datachannel.current = pc.createDataChannel("chat", {});
        console.log("created datachannel");

        await negotiate(pc);
        console.log("-------------------");
        setWebrtStatus("RTC Connecting...");

        // Open data channel
        await new Promise<void>(function (resolve) {
          datachannel.current?.addEventListener("open", () => resolve());
        });
        console.log("channel open");

        setWebrtStatus("Waiting...");

        console.log('a',  Memory.timeout)
        if (Memory.timeout && pc) {
          setTimeout(() => {
            mediaRecorder.stop();
            console.log("disconnected")
            pc.close();
            datachannel.current?.close();
            // Remove all event listeners (optional but recommended)
            pc.onicecandidate = null;
            pc.ontrack = null;
            if (sender) {
              pc.removeTrack(sender);
            }
            pc.oniceconnectionstatechange = null;
            pc.onicegatheringstatechange = null;
            pc.onsignalingstatechange = null;
            pc.onnegotiationneeded = null;
            // Set the pc instance to null (optional but recommended for cleanup)
          }, Memory.timeout * 1000)
        }

        datachannel.current!.send(
          `metadata:${JSON.stringify({
            type: "face", // face or palm will there
          })}`
        );

        // To get message from web socket server
        datachannel.current.onmessage = (event) => {
          try {
            const message = JSON.parse(event?.data);
            console.log(message);

            if (message.acknowledge) {
              console.log("Start streaming");
            } else if (message.success) {
              faceMatchsResult.current = "matched";
              console.log("Do success");
              cameraClose();
              pc.close();
            } else if (!message.success) {
              console.log("face not matched!");
              faceMatchsResult.current = "not_matched";
              pc.close();
              cameraClose();
            } else {
              console.log("Do failure");
              pc.close();
            }
          } catch (error) {
            // Handle specific types of errors here
            if (error instanceof SyntaxError) {
              console.error("Error parsing JSON:", error.message);
              // Handle JSON parsing error
            } else {
              console.error("Unexpected error:");
              // Handle other types of errors
            }
          }
        };
      } catch (error) {
        console.error("Error accessing media devices:", error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cameraClose, negotiate, selectedDeviceId]
  );

  useEffect(() => {
    if (peerConnection) {
      setInterval(() => {
      peerConnection.getStats(null).then((stats) => {
        stats.forEach((report) => {
          if (report.type === 'outbound-rtp' && report.kind === 'video') {
            console.log(`Sent Report:`, report);
            console.log(`Sent Frame Height: ${report.frameHeight}`);
            console.log(`Sent Frame Width: ${report.frameWidth}`);
            console.log(`Sent Bitrate: ${report.bitrateMean}`);
          }
        });
      });

    }, 5000)
      const handleIceConnectionStateChange = () => {
        console.log("ICE Connection State:", peerConnection.iceConnectionState);

        if (peerConnection.iceConnectionState === "connected") {
          // Connection is successful
          console.log("Connection successful!");
        }
      };

      peerConnection.addEventListener(
        "iceconnectionstatechange",
        handleIceConnectionStateChange
      );

      return () => {
        peerConnection.removeEventListener(
          "iceconnectionstatechange",
          handleIceConnectionStateChange
        );
      };
    }

    // Explicitly return undefined when peerConnection is null or undefined
    return undefined;
  }, [peerConnection]);

  return { createPeerConnection };
};

export default usePeerConnection;
