export const checkCameraAccess = (): Promise<boolean> => {
  return navigator.mediaDevices
    .getUserMedia({ video: true })
    .then(function (stream) {
      // Camera access is granted
      console.log("Camera access granted.");
      // Stop the video stream to avoid keeping the camera on
      stream.getTracks().forEach((track) => track.stop());
      return true;
    })
    .catch(function (error) {
      // Camera access denied or not available
      console.log("Camera access denied or unavailable:", error.message);
      return false;
    });
};
