import { FC } from "react";

import "./layout.scss";

interface ILayout {
  header?: JSX.Element | string;
  body?: JSX.Element | string;
  footer?: JSX.Element | string;
}
export const Layout: FC<ILayout> = ({ header, body, footer }) => {
  return (
    <div className="layout-wrapper">
      {header && <div className="layout-wrapper__header">{header}</div>}
      {body && <div className="layout-wrapper__body">{body}</div>}
      {footer && <div className="layout-wrapper__footer">{footer}</div>}
    </div>
  );
};
