import type { IPendingProfile } from "./types";
import CancelIcon from "@mui/icons-material/Cancel";
import "./pending-profile.scss";
import { Loader } from "components";
import { useEffect, useState } from "react";

import axios from 'axios';

async function processImage(base64Image: string) {
  // Convert base64 image to Blob
  if (base64Image.length < 30) {
    return;
  }
  const base64ToBlob = (base64: string, contentType: string = 'image/png') => {
    const byteCharacters = atob(base64.split(',')[1]); // Split base64 header and data
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  };

  const imageBlob = base64ToBlob(base64Image);

  // Prepare form data
  const formData = new FormData();
  formData.append('image', imageBlob, 'image.png'); // Name it the same way as the curl command
  formData.append('model_version', 'RestoreFormer++');
  formData.append('upscale', '2');
  formData.append('aligned', 'false');
  formData.append('only_center_face', 'false');

  try {
    // Make the POST request
    const response = await axios.post('https://restore.backendly.io/process/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'accept': 'application/json'
      },
      responseType: 'blob',
      timeout: 8000
    });

    // Handle response
    const blob = response.data
    const imageUrl = URL.createObjectURL(blob);
    return imageUrl;
  } catch (error) {
    console.error('Error processing image:', error);
  }
}

export const PendingProfile = ({
  img,
  handleClick,
  className = "",
  index,
  handleDeny,
  is_upscaled,
}: IPendingProfile) => {
  // Return early if no image is provided
  const [loaded, setLoaded] = useState(!!is_upscaled)
  const [enhanced, setEnhanced] = useState(img)

  useEffect(() => {
    if (img) {
      processImage(img).then(res => {
        if (res) {
          setEnhanced(res)
        }
      }).finally(() => {
        setLoaded(true);
      })
    }
  }, [img]);

  if (!img) return null;
  
  return (
    <div
      className={`d-flex gp-8 direction-column pending-profile-wrapper ${className}`}
    >
      <div className="pending-profile-wrapper__img__wrapper">
        <img
          className={`pending-profile-wrapper__img ${
            loaded === false ? "opacityhalf" : ""
          }`}
          src={enhanced}
          alt="Pending Profile"
        />
        {loaded === false && (
          <div className="pending-profile-wrapper__loader">
            <Loader dimension={40} />
          </div>
        )}
      </div>

      <div className="d-flex gp-8 align-center">
        <button className="approve-btn" onClick={handleClick}>
          Approve
        </button>
        <button onClick={() => handleDeny(index)} className="deny-btn">
          <CancelIcon />
        </button>
      </div>
    </div>
  );
};
