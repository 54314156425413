import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Home } from "./home";
import { Demo } from "./demo";
import { Event } from "./events";
import usePeerConnection from "./hooks/connect-rtc";
import useEventPeerConnection from "./hooks/event-connect-rtc";
import { getSessionDetailByPhone } from "./modal";
import { Memory } from "./memory";
import { useModel } from "./hooks";
import axios from "axios";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { AttendedState, CameraTypeState, EventIdState, EventQueueState, EventState, EventTypeState, IEvent, IEventQueue, QrState } from "./states";
import { HOST_URL } from "./event-modal";
import { Events } from "views";
import { Qr } from "views/qr";
import { useInterval } from "hooks";
import { onValue, ref } from "firebase/database";
import { database } from "utils/firebase";
import { DroneEvents } from "drone-events";

async function stopDroneEvent(
  session_id: string,
): Promise<any> {
  const url = `https://devents.backendly.io/stop`;
  
  try {
    const response = await axios.post(url, null, {
      params: {
        session_id,
      }
    });

    return response.data;
  } catch (error) {
    console.error('Failed to start event:', error);
    throw error;
  }
}


export const worker = new Worker("./worker.js");

function App() {
  const { createPeerConnection } = usePeerConnection();
  const { createPeerConnection: createEventPeerConnection } =
    useEventPeerConnection();
  const setQrs = useSetRecoilState(QrState);
  const setEvent = useSetRecoilState(EventState);
  const setEventType = useSetRecoilState(EventTypeState)
  const setAttended = useSetRecoilState(AttendedState);
  const setEventQueue = useSetRecoilState(EventQueueState);
  const [eventId, setEventId] = useRecoilState(EventIdState);

  const { loadModel } = useModel();

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      console.log('Page is about to be refreshed or closed');
      stopDroneEvent(Memory.sessionId)
      // You can also set a custom message like this:
      // event.returnValue = 'Are you sure you want to leave?';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useInterval(async () => {
    if (!eventId) {
      return;
    }
    const url = `${HOST_URL}/pipelines`;
    try {
      const {data} = await axios.get(`${url}/event-queues/${eventId}`);
      const queueData: IEventQueue = data?.data;

      if (!queueData) {
        return;
      }

      setEventQueue(queueData);

      queueData.queue.forEach(q => {
        Memory.entryQueue[q.sessionId] = true;
      });

      // removing old queue user sessions
      Object.keys(Memory.entryQueue).forEach(sessionId => {
        const foundInQueue = queueData.queue.find(q => q.sessionId === sessionId);
          if (!foundInQueue) {
            delete Memory.entryQueue[sessionId]
          }
      })
    } catch (err) {
      console.error("useInterval.queue", err)
    }
  }, 1000)

  // const uuId = GetQueryParams("id");
  // const urlType = GetUrlType(window.location.href);

  // https://biometrics.pp.satschel.com/recipient?id=65280179f50680ddb6567447
  // https://biometrics.pp.satschel.com/sender?id=65280179f50680ddb6567447
  //window.location.href

  useEffect(() => {
    if(eventId) {
      const dataRef = ref(database, eventId);
      onValue(dataRef, async (snapshot) => {
        const data = snapshot.val();
        console.log('eventIddata', data)
      })
    }
  }, [eventId])

  useEffect(() => {
    loadModel();
    const search = window.location.search;
    if (search) {
      const params = new URLSearchParams(search);
      const phone = params.get("phone");
      const rec = params.get("rec");
      const frames = params.get("frames");
      const frames_limit = params.get("frames_limit");
      const timeout = params.get("timeout");
      const eventId = params.get("eventId");
      const connect = params.get("connect");

      if (frames_limit) {
        Memory.frames_limit = Number(frames_limit);
      }

      if (frames) {
        Memory.frames = Number(frames);
      }
      if (rec) {
        Memory.match = rec === "true";
      }
      if (timeout) {
        Memory.timeout = Number(timeout);
      }
      if (eventId) {
        const url = `${HOST_URL}/pipelines`;
        axios
          .get(`${url}/events/${eventId}?type=event`)
          .then(({ data }) => {
            const eventData: IEvent = data?.data;
            if (eventData) {
              setEvent(eventData);
              const attended: Record<string, boolean> = {}
              eventData.users.forEach(user => {
                if (user.attendedAt) {
                  attended[user.sessionId] = true;
                }
              })

              Memory.eventUserList = eventData.users ?? [];

              setAttended(attended);
              setEventId(eventData._id);
              setEventType(eventData.type ?? 'both');
              Memory.eventUsers = eventData.users;
            }
            console.log("sadasd", eventData);
            setQrs(eventData?.qrIds ?? []);
          })
          .catch((err) => {
            console.error("event err", err);
          });
        Memory.eventId = eventId;
      }

      if (connect) {
        createEventPeerConnection();
      }
      if (phone) {
        const result = { phone: phone };
        console.log(result);
        if (result.phone === "4157676179") {
          createPeerConnection({} as any);
        } else {
          getSessionDetailByPhone(phone).then((session) => {
            if (session) {
              createPeerConnection(session);
              // setSessionDetail(session);
            }
          });
        }
      }
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path={"/"} element={<Home />} />
          <Route path={"/demo"} element={<Demo />} />
          <Route path={"/session-events"} element={<Event />} />
          <Route path={"/events"} element={<Events />} />
          <Route path={"/qr"} element={<Qr />} />
          <Route path={"/drone"} element={<DroneEvents />} />
          {/* <Route path={"/drone"} element={<VideoStream />} /> */}
          {/* <Route path={"/enroll/:id"} element={<Enroll />} /> */}
          {/* <Route
            path="/:id"
            element={<Facial  />}
          /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
