import { Fragment, useCallback, useEffect, useRef } from "react";

import { EventTitle } from "./components/event-title/event-title";
import { EventDescription } from "./components/event-description";

import { CameraTypeState, EventIdState, EventQueueState, EventState, IEventQueue, SelectedSessionIdState, SessionInfoState, UnknownUserState, WebRtcUserState, WebrtSessionState, WebrtStatusState } from "states";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { EventCamera } from "./components/event-camera";
import { DateTime } from "luxon";
import { AttendeeLists, MatchedProfile, PendingProfiles } from "./components";

import "./events.scss";
import { SessionModal } from "session-info";
import { CircularProgress } from "@mui/material";
import { HOST_URL } from "event-modal";
import { Memory } from "memory";
import axios from "axios";
import { IFaceScore } from "./components/event-camera/events-match";

export const getDateTime = (date: string) => {
  const formattedDate = DateTime.fromISO(date).toFormat(
    "EEEE, LLLL dd · h:mma"
  );
  return formattedDate;
};

export const EventHeader = () => {
  const event = useRecoilValue(EventState);

  const webrtcSession= useRecoilValue(WebrtSessionState);
  const webrtStatus = useRecoilValue(WebrtStatusState);

  const isStatus = webrtStatus ||  webrtcSession


  return (
    <div className="event-wrapper__header">
       <div className="event-wrapper__header__right">
        <EventTitle title={event?.name} />
        <EventDescription
          description={event?.date ? getDateTime(event?.date) : ""}
        />
      </div>
      <div className="d-flex gp-8 align-item-center">

      {/* <div> */}
        {isStatus  && <div>
          <strong style={{ paddingRight: 5 }}>WebRTC Status:</strong>{" "}
          <span>{webrtcSession ? webrtcSession : webrtStatus}</span>
        </div>}


        {/* </div> */}
        

        {/* <div>
          <EventTitle title="Prateek Singh" />
          <EventDescription description="prateek.singh@gmail.com" />
        </div>
        <img
          className="profile-img"
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0KehqXBrMLd32HsfjDoaq098WeNA0b3g_2A&s"
          alt="profile"
        /> */}
      </div>
    </div>
  );
};

export const EventManageQueue = () => {
  const eventId = useRecoilValue(EventIdState);
  const cameraType = useRecoilValue(CameraTypeState);
  const setEventQueue = useSetRecoilState(EventQueueState);
  const webrtcUsers = useRecoilValue(WebRtcUserState);

  const exitUser = useCallback(async (sessionId: string) => {
		try {
		  // SET V2
		  const HOST = `${HOST_URL}/pipelines`
      const payload = {
        sessionId: sessionId,
        eventId,
        type: 'exit',
      }

		  const {data} = await axios.post(`${HOST}/event-queues`, payload);
      setEventQueue(prev => {
        if (!prev) {
          return null;
        }
        const clone: IEventQueue = JSON.parse(JSON.stringify(prev));
        const foundSession = clone.queue.findIndex(q => q.sessionId === sessionId);
        clone.queue.splice(foundSession, 1);
        return clone;
      })
		  console.log("data", data);
		} catch(err) {
		  console.log("err", err);
		}
	}, [eventId, setEventQueue])

  const enterUser = useCallback(async (sessionId: string) => {
    if (Memory.entryQueue[sessionId]) {
      return;
    }

    try {
		  // SET V2
		  const HOST = `${HOST_URL}/pipelines`
      const payload = {
        sessionId: sessionId,
        eventId,
        type: 'entry',
      }

		  const {data} = await axios.post(`${HOST}/event-queues`, payload);
      setEventQueue(prev => {
        if (!prev) {
          return null;
        }
        const clone: IEventQueue = JSON.parse(JSON.stringify(prev));
        clone.queue.push({sessionId});
        return clone;
      });

		  console.log("data", data);
		} catch(err) {
		  console.log("err", err);
		}
	}, [eventId, setEventQueue])

  const manageQueue = useCallback(async (users: IFaceScore['users']) => {
    if (!users) {
      return;
    }

    switch (cameraType){
      case 'entry': {
        console.log('entry', Memory.entryQueue);
        for (const sessionId of Object.keys(users)) {
          if (/unknown/gi.test(sessionId)) {
            continue
          }
          await enterUser(sessionId);
        }
        break
      }
      case 'exit': {
        const enteredUsers = Memory.entryQueue;
        for (const sessionId of Object.keys(users)) {
          if (/unknown/gi.test(sessionId)) {
            continue
          }
          const found = enteredUsers[sessionId];
          if (found) {
            delete Memory.entryQueue[sessionId];
            await exitUser(sessionId)
          }
        }
        break
      }
    }

  }, [cameraType, enterUser, exitUser])

  useEffect(() => {
    if (webrtcUsers) {
      manageQueue(webrtcUsers);
    }
  }, [webrtcUsers])

  return (
    <></>
  );
};


export const Events = () => {
  const [selectedSessionId, setSelectedSessionId] = useRecoilState(SelectedSessionIdState);
  const event = useRecoilValue(EventState);

  return (
    <Fragment>
      {
        event ? <div className="event-wrapper">
        { 
          selectedSessionId ? <SessionModal
            setOpen={() => setSelectedSessionId(null)}
            open={!!selectedSessionId} 
            sessionId={selectedSessionId} 
          /> : null
        }
        {/* Event Header */}
        <EventHeader />

        {/* Event Body */}
        <div className="event-wrapper__body">
          {/* Left Section - Attendee List */}
          <div className="event-wrapper__left">
            <AttendeeLists />
          </div>

          {/* Center Section - Matched Profiles */}
          <div className="event-wrapper__center">
            <div className="d-flex direction-column relative">
              <EventCamera />

              <MatchedProfile />
            </div>
          </div>

          {/* Right Section - Unregistered Profiles */}
          <div className="event-wrapper__right">
            <PendingProfiles />
          </div>
        </div>
      </div> : <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }} className="event-wrapper">
      <CircularProgress />
      </div>
      }
      <EventManageQueue />
      
    </Fragment>
  );
};
