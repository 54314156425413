import {initializeApp} from "firebase/app";
import { DatabaseReference, getDatabase, set } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAX-qEF0bFHDOXNtyo6xeLvEKVMS8niAXs",
  authDomain: "glossy-fastness-305315.firebaseapp.com",
  databaseURL: "https://glossy-fastness-305315-default-rtdb.firebaseio.com",
  projectId: "glossy-fastness-305315",
  storageBucket: "glossy-fastness-305315.appspot.com",
  messagingSenderId: "952484154781",
  appId: "1:952484154781:web:05abe5e2da41633d247374"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export class RealTimeDB {
  static async set(ref: DatabaseReference, value: any) {
    try {
      await set(ref, value)
      console.log('Data saved successfully!');
    } catch(error) {
      console.error('Error saving data:', error);
    }
  }
}

export { database };